* {
  font-family: "Montserrat", sans-serif;
}

html,
body {
  height: 100%;
}

.error{
  margin: auto;
}

section {
  min-height: 100%;
}

.navbar {
  font-size: 14px;
  font-weight: 700;
}

.title {
  font-weight: 700;
  font-size: 48px;
  padding-top: 80px;
  text-align: center;
  padding-bottom: 20px;
}

.title::before {
  content: "";
  position: relative;
  display: block;
  height: 2px;
  width: 115px;
  margin-bottom: 30px;
  background: #f9b707;
  will-change: transform, opacity;
  margin-left: auto;
  margin-right: auto;
}

.navbar-light .navbar-nav .nav-link {
  color: #222 !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 120px;
}


.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 120px;
  height: 120px;
  margin-top: -0.5em;

  border: 10px solid #0e3b74;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}


/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 35px;
  }
}